import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import RequestDemoForm from "../../components/individualProductPage/RequestDemoForm"
import StealBackground from "../../components/individualProductPage/images/steal-background.jpg"
import SpecBarMachineImage from "../../components/individualProductPage/images/34-profile.png"
import UsaBackground from "../../components/individualProductPage/images/handmade-in-usa-banner-34.jpg"
import MachineOverviewImage from "../../components/individualProductPage/images/34-machineoverview.jpg"
import HeavyDutyBackground from "../../components/individualProductPage/images/heavy-duty-banner-34.jpg"
import MachineDimensionImage from "../../components/individualProductPage/images/34-dims.jpg"
import YBTJWalkie from "../../../static/34-YBTJ-FC.pdf"
import AnimateCylImage from "../../components/individualProductPage/images/disk-deck-loop-v2020.gif"

//Thumbnail Images
import CylindricalThumb1Image from "../../components/individualProductPage/images/34-1.jpg"
import CylindricalThumb2Image from "../../components/individualProductPage/images/34-4.jpg"
import CylindricalThumb3Image from "../../components/individualProductPage/images/34-3.jpg"
import CylindricalThumb4Image from "../../components/individualProductPage/images/34-2.jpg"
import CylindricalThumb5Image from "../../components/individualProductPage/images/34-open-5.jpg"
import DiskThumb1 from "../../components/individualProductPage/images/micromag-20d-pa-360s-view-1-thumb.jpg"
import DiskThumb2 from "../../components/individualProductPage/images/micromag-20-pa-360-view-4-thumb.jpg"
import DiskThumb3 from "../../components/individualProductPage/images/micromag-20-pa-360-view-3-thumb.jpg"
import DiskThumb4 from "../../components/individualProductPage/images/micromag-20-pa-360-view-2-thumb.jpg"
import DiskThumb5 from "../../components/individualProductPage/images/micromag-disk-360-view-5-thumb.jpg"
//End Thumbnail Images
import '../product-page-styles.scss';

//Main Images
import CylindricalMain1Image from "../../components/individualProductPage/images/34-1.jpg"
import CylindricalMain2Image from "../../components/individualProductPage/images/34-4.jpg"
import CylindricalMain3Image from "../../components/individualProductPage/images/34-3.jpg"
import CylindricalMain4Image from "../../components/individualProductPage/images/34-2.jpg"
import CylindricalMain5Image from "../../components/individualProductPage/images/34-open-5.jpg"
import DiskMain1Image from "../../components/individualProductPage/images/micromag-20d-pa-360s-view-1.jpg"
import DiskMain2Image from "../../components/individualProductPage/images/micromag-20-pa-360-view-4.jpg"
import DiskMain3Image from "../../components/individualProductPage/images/micromag-20-pa-360-view-3.jpg"
import DiskMain4Image from "../../components/individualProductPage/images/micromag-20-pa-360-view-2.jpg"
import DiskMain5Image from "../../components/individualProductPage/images/micromag-disk-360-view-5.jpg"
import MediaTile from "../../components/individualProductPage/MediaTile"
import MainSpecBar from "../../components/individualProductPage/FeaturedProduct/MainSpecBar"
import MadeInUSA from "../../components/individualProductPage/MadeInUSA"
import SectionTitle from "../../components/common/SectionTitle"
import MachineOverview from "../../components/individualProductPage/MachineOverview"
import HeavyDuty from "../../components/individualProductPage/HeavyDuty"
import MachineDimensions from "../../components/individualProductPage/MachineDimensions"

//End Main Images
import IndividualMachine from "../../components/individualProductPage/individualMachine"

const MediaTilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 840px) {
    max-width: 650px;
  }
`

const Product34Page = props => {
  return (
    <>
      <Layout>
        {/* <FeaturedProduct> */}
        <IndividualMachine
          displayV2="none"
          cylImage={AnimateCylImage}
          displayCyl="none"
          displayDisk="none"
          displayOrbital="none"
          displayChoose="none"
          displayTitle="none"
          cylindricalThumb1={CylindricalThumb1Image}
          cylindricalThumb2={CylindricalThumb2Image}
          cylindricalThumb3={CylindricalThumb3Image}
          cylindricalThumb4={CylindricalThumb4Image}
          cylindricalThumb5={CylindricalThumb5Image}
          cylindricalMain1={CylindricalMain1Image}
          cylindricalMain2={CylindricalMain2Image}
          cylindricalMain3={CylindricalMain3Image}
          cylindricalMain4={CylindricalMain4Image}
          cylindricalMain5={CylindricalMain5Image}
          diskThumb1={DiskThumb1}
          diskThumb2={DiskThumb2}
          diskThumb3={DiskThumb3}
          diskThumb4={DiskThumb4}
          diskThumb5={DiskThumb5}
          diskMain1={DiskMain1Image}
          diskMain2={DiskMain2Image}
          diskMain3={DiskMain3Image}
          diskMain4={DiskMain4Image}
          diskMain5={DiskMain5Image}
          brochureLink="https://www.dropbox.com/sh/qxwutzvg3snsvd7/AAAbRfBZ3Yijhf1noA_nAobJa/FC-34-TR-BROCHURE.pdf?dl=0"
          techLink="https://www.factorycat.com/docs/techSpecs/FC-34-TR-SALESSHEET.pdf"
          partsLink="https://www.dropbox.com/s/aj5r39gcrvv8tj6/FC-34-P-EN.pdf?dl=0"
          opLink="https://www.factorycat.com/docs/manualsOp/FC-34-OP-EN.pdf"
          title="34 Walk Behind Floor Sweeper"
          textContent="The Factory Cat model 34 Walk Behind Sweeper is the machine that built our company. A Industrial Sweeper famous for its ability to survive decades in the harshest applications: including mines, brick manufacturing and rugged steel mills. Designed almost 25 years ago, this floor sweeper can sweep through thick saw dust found in furniture manufacturing plants and the dust in paper mills."
          videoSource="https://www.youtube.com/embed/9SAdO5PMuuU"
          pageUrl=""
          upLink="/ride-on-floor-sweepers/tr"
          displayDown="none"
          machineName="34"
          pmLink=""

        />
        <div className="tiles-container">
          <MediaTile
            title="Why FactoryCat ?"
            subTitle="Walk Behind Floor Sweeper"
            link={YBTJWalkie}
          />
          <MediaTile
            title="Videos"
            subTitle="Walk Behind Floor Sweeper"
            link="/videos"
          />
          <MediaTile
            link="/images#walkie-sweepers"
            title="Images"
            subTitle="Walk Behind Floor Sweeper"
          />
          <MediaTile
            title="Applications"
            subTitle="Walk Behind Floor Sweeper"
            link="/applications"
          />
        </div>
        <MainSpecBar
          bgImage={StealBackground}
          machineImage={SpecBarMachineImage}
          myColor="white"
          machineName="34"
          category="Walk Behind Floor Sweeper"
          path='34"'
          path2="(86 cm)"
          runTime="Up to 5 Hours"
          displayTank="none"
          // solution="Solution: 10 Gal"
          // solutionLiter="(37.6L)"
          // recovery="10 Gal"
          // recoveryLiter="(37.6L)"
        />
        <MadeInUSA bgImage={UsaBackground} />
        <div className="spec-title-wrapper">
        <SectionTitle title="Machine Overview" />
        </div>
        <MachineOverview image={MachineOverviewImage} />
        <HeavyDuty
          bgImage={HeavyDutyBackground}
          innerText="HEAVY DUTY CLEANING"
        />
        <MachineDimensions
          image={MachineDimensionImage}
          sizeTitle="Size (L x W x H):"
          size='58" x 31" x 38'
          weightTitle="Weight (w/o batteries):"
          weight="295 - 345 lbs"
          voltageTitle="System Voltage:"
          voltage="12 VDC"
          stdBatteryTitle="Standard Battery:"
          battery="175 ah WET"
          powerTitle="Power:"
          power="1.0hp"
          tankTitle="Chassis Construction:"
          tank='1/8" Powder Coated Steel'
        />
        {/* <OptionsBar>Options</OptionsBar>
        <MiniHdOptions /> */}

        <div id="demo-form"></div>
        <SectionTitle title="Request a Demo" />
        <div className="spec-title-wrapper">
        <RequestDemoForm image={CylindricalMain1Image} />
        </div>
      </Layout>
    </>
  )
}

export default Product34Page
